import { createStore, applyMiddleware, combineReducers } from "redux";

// // Logger with default options
import logger from "redux-logger";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import reducer from "./reducers/index";

const rootReducer = combineReducers(reducer);

const configureStore = () => {
  let store = createStore(rootReducer, {}, applyMiddleware(thunk));
  return { store };
};

const combineStore = configureStore();
export default combineStore;

import React from "react";
import { Switch, Route } from "react-router-dom";
import AddNew from "./pages/AddNew";
import PlaceHolder from "./pages/PlaceHolder";
import Create from "./pages/Create";
import Layout from "./pages/Layout";

export default function Routes() {
  return (
    <Switch>
      <Route exact path={["/", "/placeholder", "/started"]}>
        <Layout>
          <Route exact path="/started" component={AddNew} />
          <Route exact path="/placeholder" component={PlaceHolder} />
          {/* <Route exact path="/build" component={BuildProject} /> */}
          <Route exact path="/" component={Create} />
        </Layout>
      </Route>
    </Switch>
  );
}

import {
  ADD_ITEM,
  SET_PROJECT_NAME,
  SHOW_SIDEBAR,
  SET_ACTIVE_ITEM,
  SHOW_RECORD_BAR,
  SET_SELECTED_VOICE,
  UPDATE_SELECTED_ITEM,
  SET_RECORDING,
} from "../constants";
import axios from "axios";
import { message } from "antd";
// actions
export const addItem = (cond) => (dispatch) => {
  dispatch({
    type: ADD_ITEM,
    payload: cond,
  });

  dispatch(setActiveItem(cond));
};

export const setProjectName = (cond) => ({
  type: SET_PROJECT_NAME,
  payload: cond,
});

export const setRightSidebar = (cond) => ({
  type: SHOW_SIDEBAR,
  payload: cond,
});

export const setActiveItem = (cond) => ({
  type: SET_ACTIVE_ITEM,
  payload: cond,
});

export const showRecordBar = (cond) => ({
  type: SHOW_RECORD_BAR,
  payload: cond,
});

export const setSidebarVoice = (cond) => ({
  type: SET_SELECTED_VOICE,
  payload: cond,
});

export const updateSelectedItem = (cond) => ({
  type: UPDATE_SELECTED_ITEM,
  payload: cond,
});

export const setRecording = (cond) => ({
  type: SET_RECORDING,
  payload: cond,
});

export const submitTranscribeForm = async (file, filename) => {
  var formData = new FormData();
  formData.append("file", file, filename);
  formData.append("json", true);
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_URI}/transcribe`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((resp) => {
      const responseData = resp.data.data;

      return responseData;
      // setIsModalVisible(false);
      // setTranscribeLoading(false);

      // dispatch(addItem(responseData));
      // history.push({
      //   pathname: "/placeholder",
      // });
    })
    .catch((e) => {
      message.error(e.message);
      return false;
    });
  return result;
};

import React, { useState, useEffect } from "react";
import style from "../styles/placeHolder.module.css";
import { BsPlayCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { initializeWaveForm } from "../helpers/helpers";
import toWav from "audiobuffer-to-wav";
import axios from "axios";
import { v4 as uuid } from "uuid";

const audioCtx = new AudioContext();
const origDataId = uuid();

export default function MusicComponent(props) {
  const [waveform, setWaveform] = useState(null);
  const [originalAudioBuffer, setOriginalAudioBuffer] = useState([]);

  const { playing, setPlaying, audioData } = props;

  useEffect(() => {
    if (audioData.music) {
      downloadAudioAndSaveToBuffer(audioData.music.url, origDataId);
    }
  }, [audioData]);

  useEffect(() => {
    setWaveform(initializeWaveForm(`#waveform${props.order}`));
  }, []);

  const downloadAudioAndSaveToBuffer = (audioSource, id) => {
    axios({
      method: "GET",
      url: audioSource,
      responseType: "arraybuffer",
    }).then((resp) => {
      audioCtx.decodeAudioData(resp.data, (audioBuffer) => {
        setOriginalAudioBuffer({ ...originalAudioBuffer, [id]: audioBuffer });
      });
    });
  };

  useEffect(() => {
    if (originalAudioBuffer[origDataId]) {
      const newArrayBuffer = toWav(originalAudioBuffer[origDataId]);
      const newBlob = new Blob([newArrayBuffer], { type: "audio/mp3" });
      waveform.loadBlob(newBlob);
    }
  }, [originalAudioBuffer]);
  const handlePlay = () => {
    const newPlaying = !playing;
    setPlaying(newPlaying);
    waveform.playPause();
  };
  return (
    <div className={style.music}>
      <div className="w-100">
        <div className="d-flex align-items-center">
          <p className="mb-0">
            {audioData && audioData.music
              ? audioData.music.name
              : "No Music Selected"}
          </p>
        </div>
        <div
          style={{ opacity: audioData.music ? 1 : 0 }}
          id={`waveform${props.order}`}
          className="w-100"
        />
        <br />
        <button
          ref={props.playButton}
          className="btn btn-success mt-5"
          onClick={handlePlay}
          style={{ opacity: 0, position: "absolute", top: 0 }}
        >
          {!playing ? "Play" : "Pause"}
        </button>
      </div>
    </div>
  );
}

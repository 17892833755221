import React from "react";
import style from "../styles/addNew.module.css";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { message, Spin } from "antd";
import axios from "axios";
import EnterNamePopup from "../components/EnterNamePopup";
import { useSelector } from "react-redux";
import RecordBar from "../components/RecordBar";
import { useDispatch } from "react-redux";
import { setRightSidebar } from "../redux/actions";
export default function AddNew() {
  const dispatch = useDispatch();
  const { projectName } = useSelector((state) => state.placeholder);

  React.useEffect(() => {
    dispatch(setRightSidebar(false));
  }, []);
  return (
    <>
      <div className={style.AddNewSection}>
        <h1>
          {" "}
          <img src="/images/projectImg.svg" alt="" />
        </h1>
        <h4 style={{ color: "black" }}>{projectName}</h4>
        <EnterNamePopup />
        <div
          style={{ marginLeft: "-128px" }}
          className={`${style.getStarted} d-flex flex-column justify-content-center align-items-center`}
        >
          <img className="mb-5" src="/images/bigMicIcon.svg" alt="" />
          <h3>Get started with Your Project</h3>
          <h5>Start recording or drag in audio</h5>
        </div>
      </div>
    </>
  );
}

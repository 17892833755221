import React, { useRef, useState } from "react";
import style from "../styles/placeHolder.module.css";
import { BsPlayCircle, BsFillPauseCircleFill } from "react-icons/bs";
import TranscribeEditor from "../components/TranscribeEditor";
import MusicComponent from "../components/MusicComponent";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setActiveItem } from "../redux/actions";
import TranscribeEditorTTs from "./TrasnscribeEditorTTs";
import RecordNowEditor from "./RecordNowEditor";

export default function TranscribeComponent({ data, order }) {
  const location = useLocation();
  const playButton = useRef();
  const [playing, setPlaying] = useState(false);
  const dispatch = useDispatch();
  const { type, id } = data;
  const { activeItem } = useSelector((state) => state.placeholder);

  // React.useEffect(() => {
  //   window.addEventListener("click", function (e) {
  //     console.log(document.getElementById(`item${order}`));
  //     if (document.getElementById(`item${order}`).contains(e.target)) {
  //       console.log("clicked inside");
  //       // Clicked in box
  //     } else {
  //       dispatch(setActiveItem(null));
  //     }
  //   });
  // }, []);

  return (
    <div className={`${style.music} `} id={`item${order}`}>
      <div
        className={style.musicalNote}
        style={{ backgroundColor: data.color }}
      >
        <img
          src={data.type == "tts" && data.voice ? data.voice.avatar : data.icon}
          alt=""
          className={
            data.type == "tts" && data.voice ? style.avatarPic : style.typeIcon
          }
        />
      </div>
      <div
        className={`${style.content} `}
        onClick={() => dispatch(setActiveItem(data))}
        style={{
          backgroundColor: data.color,
          border:
            activeItem && activeItem.id == id
              ? "3px solid gray"
              : "3px solid transparent",
        }}
      >
        <span className={style.timer} style={{ color: "#000" }}>
          2:32 sec
        </span>
        <div className="d-flex align-items-center">
          <button
            style={{ color: "#000" }}
            onClick={() => {
              playButton.current.click();
            }}
          >
            {playing ? <BsFillPauseCircleFill /> : <BsPlayCircle />}
          </button>
          <p className="mb-0" style={{ color: "black" }}>
            Recorded and Transcribed 01/23/2021 8:34 am
          </p>
        </div>
        <div className={"mt-3 px-3"}>
          {type == "tts" ? (
            <TranscribeEditorTTs
              order={order}
              playButton={playButton}
              playing={playing}
              setPlaying={setPlaying}
              audioData={data}
            />
          ) : type == "music" ? (
            <MusicComponent
              order={order}
              playButton={playButton}
              playing={playing}
              setPlaying={setPlaying}
              audioData={data}
            />
          ) : type == "record" ? (
            <RecordNowEditor
              audioData={data}
              playButton={playButton}
              playing={playing}
              setPlaying={setPlaying}
              order={order}
            />
          ) : (
            <TranscribeEditor
              audioData={data}
              playButton={playButton}
              playing={playing}
              setPlaying={setPlaying}
              order={order}
            />
          )}
        </div>
      </div>
      <button>
        <img src="/images/more.svg" alt="" />
      </button>
    </div>
  );
}

import React from "react";
import style from "../styles/addNew.module.css";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { message, Spin, Modal } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addItem, showRecordBar } from "../redux/actions";
import { submitTranscribeForm } from "../redux/actions";
import { v4 as uuid } from "uuid";
export default function AddItemPopup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transcribeLoading, setTranscribeLoading] = useState(false);

  async function submitForm(type, file) {
    setTranscribeLoading(type);
    if (file == null) {
      message.error("Please select a file first");
      setTranscribeLoading(false);
      return;
    }
    const result = await submitTranscribeForm(file, file.name);

    setIsModalVisible(false);
    setTranscribeLoading(false);

    if (result) {
      dispatch(
        addItem({
          ...result,
          type,
          id: uuid(),
          icon: "/images/upload_2.svg",
          color: "#cfd8dd",
        })
      );
      history.push({
        pathname: "/placeholder",
      });
    }
  }
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalVisible(true);
        }}
      >
        <img src="/images/add.svg" alt="" />
        Add New item
      </a>

      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={"addItemPopup"}
      >
        <div className={style.tracksSection} style={{ height: "550px" }}>
          <div className="d-flex align-items-center justify-content-between">
            <h2>Add New item</h2>
            <img
              src="/images/close.svg"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsModalVisible(false);
              }}
              alt=""
            />
          </div>
          <h6>
            <img src="/images/upload.svg" alt="" />
            Drag and Drop Audio Files Here
          </h6>
          <div className={style.cards}>
            <div
              className={style.card}
              onClick={() => {
                dispatch(showRecordBar(true));
                setIsModalVisible(false);
                dispatch(
                  addItem({
                    type: "record",
                    id: uuid(),
                    icon: "/images/record.svg",
                    color: "#a7feeb",
                  })
                );
                history.push({
                  pathname: "/placeholder",
                });
              }}
            >
              <img src={`/images/record.svg`} alt="" />
              <p>Record Yourself</p>
            </div>
            <div
              className={style.card}
              onClick={() => {
                setIsModalVisible(false);
                dispatch(
                  addItem({
                    type: "tts",
                    id: uuid(),
                    icon: "/images/audio.svg",
                    color: "#feab91",
                  })
                );
                history.push({
                  pathname: "/placeholder",
                });
              }}
            >
              <img src={`/images/audio.svg`} alt="" />
              <p>Text to Audio</p>
            </div>{" "}
            <div
              className={style.card}
              onClick={() => {
                dispatch(showRecordBar(true));
                setIsModalVisible(false);
                dispatch(
                  addItem({
                    type: "music",
                    id: uuid(),
                    icon: "/images/music.svg",
                    color: "#e7b2e9",
                  })
                );
                history.push({
                  pathname: "/placeholder",
                });
              }}
            >
              <img src={`/images/music.svg`} alt="" />
              <p>Music</p>
            </div>{" "}
            <div className={style.card}>
              <img src={`/images/image.svg`} alt="" />
              <p>IMAGE</p>
            </div>{" "}
            <div className={style.card}>
              <img src={`/images/video.svg`} alt="" />
              <p>VIDEO</p>
            </div>{" "}
            <div className={style.card} style={{ position: "relative" }}>
              <img src={`/images/upload_2.svg`} alt="" />
              {transcribeLoading == "upload" ? (
                <Spin style={{ display: "block", marginTop: "4px" }} />
              ) : (
                <p>Upload File</p>
              )}
              <input
                type="file"
                name="file"
                accept=".mp3"
                className="form-control"
                id="file"
                onChange={(e) => {
                  submitForm("upload", e.target.files[0]);
                }}
              />
            </div>
            <div className={style.card}>
              <img src={`/images/interview_2.svg`} alt="" />
              <p>Interview</p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

import React, { useState, useEffect } from "react";
import style from "../../styles/sideMenu.module.css";
import voices from "../../data/voices.json";
import ImageInitial from "../ImageInitial";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedItem } from "../../redux/actions";
export default function MusicSidebar() {
  const [selectedVoice, setSelectedVoice] = useState({});
  const dispatch = useDispatch();
  const { activeItem } = useSelector((state) => state.placeholder);

  useEffect(() => {
    if (activeItem.type == "tts" && activeItem.voice) {
      setSelectedVoice(activeItem.voice);
    }
  }, [activeItem]);
  let arr = [
    {
      name: "Funked Up",
      url: "https://storage.googleapis.com/audio_one_background_music/190_full_funk-d-up_0175.mp3",
      id: "funked1",
    },
    {
      name: "OMG!",
      url: "https://storage.googleapis.com/audio_one_background_music/190_instrumental1_omg_0219.mp3",
      id: "omg1",
    },
    {
      name: "Trends & Styles",
      url: "https://storage.googleapis.com/audio_one_background_music/216_full_trends-styles_0136.mp3",
      id: "trends1",
    },
    {
      name: "Shut Up and Take My Money",
      url: "https://storage.googleapis.com/audio_one_background_music/232_full_shutup-and-take-my-money_0151.mp3",
      id: "shut1",
    },
    {
      name: "Glitter Bomb",
      url: "https://storage.googleapis.com/audio_one_background_music/258_full_glitter-bomb_0142.mp3",
      id: "glitter1",
    },
    {
      name: "Party Vibes",
      url: "https://storage.googleapis.com/audio_one_background_music/364_full_party-vibes_0148.mp3",
      id: "party1",
    },
    {
      name: "Just Rolling",
      url: "https://storage.googleapis.com/audio_one_background_music/422_full_just-rolling_0138.mp3",
      id: "just1",
    },
    {
      name: "Out Of Control",
      url: "https://storage.googleapis.com/audio_one_background_music/530_full_out-of-control_0156.mp3",
      id: "out1",
    },
  ];
  return (
    <div>
      <h2>MUSIC</h2>
      <div className={style.invite}>
        <input type="text" placeholder="Find People to Invite" />
        <button>INVITE</button>
      </div>
      <h4
        className="mb-4 mt-5"
        style={{
          fontSize: 17,
          fontWeight: "bold",
        }}
      >
        Continuos Music
      </h4>
      <div
        className={style.userInvitation}
        style={{ overflow: "auto", height: "64vh", paddingBottom: "3em" }}
      >
        {arr.map((item) => (
          <div
            className={`${style.userItem} d-flex mt-3 align-items-center`}
            onClick={() => {
              setSelectedVoice(item);
              dispatch(
                updateSelectedItem({
                  ...activeItem,
                  music: item,
                })
              );
            }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ flex: 1 }}
            >
              <div className="d-flex align-items-center w-100">
                <div style={{ marginRight: "1em" }}>
                  <ImageInitial fullname={item.name} />
                </div>
                <div
                  className="d-flex justify-content-between "
                  style={{ flex: 1, paddingRight: "2em" }}
                >
                  <div>
                    <h6
                      style={{
                        color:
                          selectedVoice.name == item.name ? "#38ef7d" : "black",
                      }}
                    >
                      {item.name}
                    </h6>
                  </div>
                  <img src="/images/blackPlayIcon.svg" />{" "}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import React from "react";
import style from "../styles/placeHolder.module.css";
import { BsPlayCircle } from "react-icons/bs";
import MusicComponent from "../components/MusicComponent";
import { useLocation, useHistory } from "react-router-dom";
import TranscribeComponent from "../components/TranscribeComponent";
import { useSelector } from "react-redux";
export default function PlaceHolder() {
  const { items } = useSelector((state) => state.placeholder);
  const { projectName } = useSelector((state) => state.placeholder);

  return (
    <div className={style.PlaceHolderSection}>
      <h1>PROJECT</h1>
      <h4>{projectName}</h4>
      <div className={style.tracksSection}>
        {/* <MusicComponent /> */}
        {items.map((item, index) => (
          <TranscribeComponent order={index} data={item} />
        ))}
      </div>
    </div>
  );
}

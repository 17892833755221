import React from "react";
import style from "../styles/projectCard.module.css";
import { BsPlayCircle } from "react-icons/bs";
import { HeartOutlined } from "@ant-design/icons";

export default function ProjectCard() {
  return (
    <div className={style.ProjectCard}>
      <div className="d-flex align-items-center mb-3">
        <button>
          <BsPlayCircle />
        </button>
        <h5>Why Some Millennials Are Sitting Out The Market Rally</h5>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <div className="mb-2">
            <h6>Source</h6>
            <p>Charkize G.</p>
          </div>
          <div>
            <h6>Publish Date</h6>
            <p>01/03/2021</p>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <h6>Category</h6>
            <p>Business</p>
          </div>
          <div>
            <h6>Creation Date</h6>
            <p>01/03/2021</p>
          </div>
        </div>
      </div>
      <h6 className="mb-1">Type</h6>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img src="/images/blog.svg" alt="" className="me-2" />
          <p>Audio</p>
        </div>
        <div className="d-flex align-items-center">
          <img src="/images/add_3.svg" alt="" />
          <img src="/images/cluster-data-group-organize.svg" alt="" />
          <HeartOutlined />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import style from "../styles/layout.module.css";
import RightSidear from "../components/RightSidebar";
import RecordBar from "../components/RecordBar";
import { useSelector } from "react-redux";
export default function Layout({ children }) {
  const { showRecordBar } = useSelector((state) => state.placeholder);
  return (
    <>
      <Sidebar />
      <Header />
      <RightSidear />
      {showRecordBar ? <RecordBar /> : ""}

      <div className={style.topGradient}></div>
      <div className={style.bottomGradient}></div>

      <div className="layout-wrapper">{children}</div>
    </>
  );
}

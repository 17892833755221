import React from "react";
import style from "../styles/create.module.css";
import ProjectCard from "../components/ProjectCard";
import { useHistory } from "react-router-dom";
export default function Create() {
  const history = useHistory();

  return (
    <div className={style.CreateSection}>
      <h1>
        <img src="/images/createImg.svg" alt="" />
      </h1>
      <div
        className={style.footer}
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(241, 241, 241, 0) 0%, rgba(241, 241, 241, 0.78) 45%, #f1f1f1 100%)",
        }}
      ></div>
      <div className={style.CreateCard}>
        <img
          onClick={() => history.push("/started")}
          src="/images/add_2.svg"
          alt=""
        />{" "}
        <span>Create a new project</span>
      </div>
      <h4 className="mb-2">RECENT PROJECTS</h4>
      <div className={style.projectsSection}>
        <ProjectCard />
        <ProjectCard />
        <ProjectCard />
        <ProjectCard />
        <ProjectCard />
        <ProjectCard />
        <ProjectCard />
        <ProjectCard />
      </div>
    </div>
  );
}

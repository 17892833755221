import React from "react";
import style from "../styles/addNew.module.css";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { message, Spin, Modal } from "antd";
import axios from "axios";

export default function AddItemPopup() {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const cards = [
    { icon: "podcast.svg", title: "Podcast (MP3)" },
    { icon: "instagram-stories.svg", title: "Story" },
    { icon: "youtube.svg", title: "video (MP4)" },
    { icon: "audio-gram.svg", title: "audiogram" },
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalVisible(true);
        }}
      >
        <img src="/images/buildProject.svg" alt="" />
        BUILD PROJECT
      </a>

      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={"addItemPopup buildProjectPopup"}
      >
        <div className={style.BuildProject}>
          <div className={style.tracksSection} style={{ height: "500px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <h2>Build Project</h2>
              <img
                src="/images/close.svg"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsModalVisible(false);
                }}
                alt=""
              />
            </div>
            <h6>
              <b>
                {" "}
                <img src="/images/build.svg" alt="" />
                Build your Project into the Following Media Formats Below
              </b>
            </h6>
            <div className={style.cards} style={{ justifyContent: "center" }}>
              {cards.map((item) => (
                <div className={style.card}>
                  <img src={`/images/${item.icon}`} alt="" />
                  <p>{item.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

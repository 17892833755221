import { v4 as uuid } from "uuid";
import audioBufferUtils from "audio-buffer-utils";
import WaveSurfer from "wavesurfer.js";

const updateTimesOnEditor = (transcribeData) => {
  for (let i = 0; i < transcribeData.items.length; i++) {
    const item = transcribeData.items[i];
    const audioBlock = document.querySelector(
      `.audio-block[d-id="${item.id}"]`
    );
    audioBlock.setAttribute("d-st", item.st);
    audioBlock.setAttribute("d-et", item.et);
    audioBlock.setAttribute("d-len", item.len);
  }
};

const createBreathSpan = (seconds, background = false) => {
  const breathSpan = document.createElement("span");
  breathSpan.classList.add("audio-block");
  breathSpan.classList.add("audible");
  breathSpan.setAttribute("d-len", seconds);
  breathSpan.setAttribute("d-type", "breath");
  breathSpan.setAttribute("d-id", uuid());
  breathSpan.setAttribute(
    "d-breath-type",
    background ? "background-breath" : "user-breath"
  );
  breathSpan.title = `Breath ${seconds} Seconds`;
  breathSpan.textContent =
    seconds === 1 || seconds === 0.5 ? "🕐" : seconds === 2 ? "🕑" : "🕒";
  if (background) breathSpan.textContent = "🎵";
  return breathSpan;
};

const updateTranscribeData = (originalTranscribeData) => {
  const audioBlocks = document.querySelectorAll(
    '.audio-block:not([d-type="space"])'
  );

  const newTranscribeData = { items: [], config: {} };

  for (let i = 0; i < audioBlocks.length; i++) {
    const cb = audioBlocks[i];
    const src = cb.getAttribute("d-src");
    const td = originalTranscribeData[src];
    const cbtd = td?.items.find((i) => i.id === cb.getAttribute("d-id"));
    let st;
    let et;

    if (i === 0) {
      // Start Time
      st = td.items[0].st;

      // End Time
      et = st + Number(cb.getAttribute("d-len"));
    } else {
      // Start Time
      st = newTranscribeData.items[i - 1].et;
      // End Time
      et = Number((st + Number(cb.getAttribute("d-len"))).toFixed(2));
    }

    const item = {
      id: cb.getAttribute("d-id"),
      st_orig: cbtd ? cbtd.st : null,
      et_orig: cbtd ? cbtd.et : null,
      st,
      et,
      src,
      len: Number(cb.getAttribute("d-len")),
      type: cb.getAttribute("d-type"),
      content: cb.textContent,
    };
    newTranscribeData.items.push(item);
  }

  return newTranscribeData;
};

// Generate New Buffer based on new transcribe data
const createNewBuffer = async (
  transcribeData,
  originalAudioBuffer,
  origDataId
) => {
  console.log(transcribeData, originalAudioBuffer, origDataId);
  const allBuffers = [];
  for (let i = 0; i < transcribeData.items.length; i++) {
    const td = transcribeData.items[i];
    if (td.type !== "punctuation") {
      const buff = originalAudioBuffer[td.src]
        ? originalAudioBuffer[td.src]
        : originalAudioBuffer[origDataId];
      if (td.type === "pronunciation") {
        const start = Number(td.st_orig) * buff.sampleRate;
        const end = Number(td.et_orig) * buff.sampleRate;
        const newBuffer = await audioBufferUtils.slice(buff, start, end);
        allBuffers.push(newBuffer);
      } else if (td.type === "breath") {
        const newBuffer = await audioBufferUtils.create(
          td.len * buff.sampleRate,
          buff.numberOfChannels,
          buff.sampleRate
        );
        allBuffers.push(newBuffer);
      }
    }
  }

  const finalBuffer = await audioBufferUtils.concat(...allBuffers);
  return finalBuffer;
};

const getSelection = () => {
  let selection = null;
  const selObj = window.getSelection();
  if (selObj) {
    const range = selObj.getRangeAt(0);
    if (range) {
      selection = {
        startElement: range.startContainer.parentElement,
        endElement: range.endContainer.parentElement,
      };

      let selStartTime = selection.startElement.getAttribute("d-st");

      let selEndTime = selection.endElement.getAttribute("d-et");

      if (selection.startElement !== selection.endElement) {
        if (!selStartTime) {
          let condition = true;
          let nextElement = selection.startElement;
          do {
            nextElement = nextElement.nextElementSibling;
            const elementType = nextElement.getAttribute("d-type");
            condition =
              elementType === "pronunciation" || elementType === "breath";
          } while (!condition);
          selection.startElement = nextElement;
        }
        if (!selEndTime) {
          let condition = true;
          let prevElement = selection.endElement;
          do {
            prevElement = prevElement.previousElementSibling;
            const elementType = prevElement.getAttribute("d-type");
            condition =
              elementType === "pronunciation" || elementType === "breath";
          } while (!condition);
          selection.endElement = prevElement;
        }
      }
    }
  }
  return selection;
};

const getElementDetails = (element) => {
  const details = {
    id: element.getAttribute("d-id"),
    src: element.getAttribute("d-src"),
    st: Number(element.getAttribute("d-st")),
    et: Number(element.getAttribute("d-et")),
    len: Number(element.getAttribute("d-len")),
    type: element.getAttribute("d-type"),
    content: element.textContent,
  };

  return details;
};

const initializeWaveForm = (id) => {
  const newWaveForm = WaveSurfer.create({
    barWidth: 2,
    cursorWidth: 1,
    barRadius: 2,
    barGap: 2,
    container: id,
    backend: "WebAudio",
    height: 40,
    progressColor: "#916592",
    responsive: true,
    waveColor: "grey",
    cursorColor: "blue",
    interact: true,
    barMinHeight: 10,
    barHeight: 9,
    plugins: [WaveSurfer.regions.create({})],
  });
  return newWaveForm;
};

export {
  createBreathSpan,
  updateTimesOnEditor,
  updateTranscribeData,
  createNewBuffer,
  getSelection,
  getElementDetails,
  initializeWaveForm,
};

// @todo: Implement rangy plugin for range selection
import "medium-editor/dist/css/medium-editor.min.css";
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import WaveSurfer from "wavesurfer.js";
import axios from "axios";
import toWav from "audiobuffer-to-wav";
import MediumEditor from "medium-editor";
// import audioBufferUtils from 'audio-buffer-utils';
import EditorButton from "../helpers/EditorButton";
import Regions from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import { v4 as uuid } from "uuid";
import { Spin } from "antd";
import {
  createBreathSpan,
  updateTimesOnEditor,
  updateTranscribeData,
  createNewBuffer,
  getSelection,
  getElementDetails,
  initializeWaveForm,
} from "../helpers/helpers";
import { getBackground } from "../helpers/backgrounds";
import { Howl } from "howler";
import { useSelector } from "react-redux";
import Backgrounds from "./Backgrounds";
import $ from "jquery";
import {
  downloadAudioAndConvertToBuffer,
  createLoopedAudio,
} from "../helpers/audiohelpers";
WaveSurfer.regions = Regions;

// milestone

const audioCtx = new AudioContext();
const origDataId = uuid();

function TranscribeEditor(props) {
  const [originalTranscribeData, setOriginalTranscribeData] = useState({
    [origDataId]: { items: props.audioData.results, config: {} },
  });
  const [modifiedTranscribeData, setModifiedTranscribeData] = useState({
    items: props.audioData.results.map((i) => ({
      ...i,
      src: origDataId,
    })),
    config: {},
  });
  const [originalAudioBuffer, setOriginalAudioBuffer] = useState([]);
  const { playing, setPlaying } = props;
  const playingRef = useRef(playing);
  const [waveform, setWaveform] = useState(null);
  const [editor, setEditor] = useState(null);
  const [breathPopupVisibility, setBreathPopupVisibility] = useState("none");
  const [loading, setLoading] = useState(false);
  const [backgrounds, setBackgrounds] = useState([]);
  const backgroundsRef = useRef(backgrounds);
  const [backgroundPopupVisibility, setBackgroundPopupVisibility] =
    useState("none");
  const addElementRef = useRef();
  const dropdownRef = useRef();
  const { activeItem } = useSelector((state) => state.placeholder);

  // Initialize Waveform using Wavesurfer

  useEffect(() => {
    if (editor)
      if (activeItem.id !== props.audioData.id) {
        editor.destroy();
      } else {
        editor.setup();
      }
  }, [props.data, activeItem]);

  useEffect(() => {
    downloadAudioAndSaveToBuffer(props.audioData.audioSource, origDataId);
    initializeEditor();
    setWaveform(initializeWaveForm(`#waveform${props.order}`));

    window.addEventListener("click", function (e) {
      if (
        document
          .getElementById(`backgroundPopup${props.order}`)
          .contains(e.target) ||
        document
          .getElementById(`breathPopup${props.order}`)
          .contains(e.target) ||
        document
          .getElementById(`medium-editor-toolbar-${props.order + 1}`)
          .contains(e.target)
      ) {
        console.log("clicked inside");
        // Clicked in box
      } else {
        setBreathPopupVisibility("none");
        setBackgroundPopupVisibility("none");
      }
    });
  }, []);

  // Download Audio from S3 and Save Audio Buffer in State
  const downloadAudioAndSaveToBuffer = (audioSource, id) => {
    axios({
      method: "GET",
      url: audioSource,
      responseType: "arraybuffer",
    }).then((resp) => {
      audioCtx.decodeAudioData(resp.data, (audioBuffer) => {
        setOriginalAudioBuffer({ ...originalAudioBuffer, [id]: audioBuffer });
      });
    });
  };

  const onEditableClick = (e) => {
    let sel = window.getSelection();
    let str = sel.anchorNode.nodeValue,
      len = str.length;
    var b;
    var a = (b = sel.anchorOffset);
    if (b !== 0) {
      showHidePlusIcon(" ", e);
      return;
    }
    while (str[a] !== " " && a--) {}

    if (str[a] === " ") {
      a++; // start of word
    }
    if (str[b - 1] === " ") {
      b--;
    } else {
      while (str[b] !== " " && b++ < len) {} // end of word+1
    }
    const value = str.substring(a, b);
    showHidePlusIcon(value, e);
  };

  const showHidePlusIcon = (value, e) => {
    if (value === " ") {
      const topPos = e.pageY - 21 - 15;
      const leftPos = e.pageX - 8;
      const drpdownleftPos = e.pageX - 20;
      if (!addElementRef?.current?.style) {
        return;
      }

      addElementRef.current.style.top = `${topPos}px`;
      addElementRef.current.style.left = `${leftPos}px`;
      addElementRef.current.classList.remove("d-none");

      dropdownRef.current.style.left = `${drpdownleftPos}px`;
      dropdownRef.current.style.top = `${topPos}px`;
      if (![...dropdownRef.current.classList].includes("d-none")) {
        dropdownRef.current.classList.add("d-none");
        return;
      }
    } else {
      addElementRef.current.classList.add("d-none");
      dropdownRef.current.classList.add("d-none");
    }
  };

  const onPlusIconClick = () => {
    dropdownRef.current.classList.remove("d-none");
  };

  const onDropdownElementClick = () => {
    dropdownRef.current.classList.add("d-none");
    addElementRef.current.classList.add("d-none");
  };

  // Initialize Editor.js
  const initializeEditor = () => {
    const newEditor = new MediumEditor(`#editor${props.order}`, {
      disableReturn: true,
      toolbar: {
        buttons: ["breathbutton", "uploadbutton", "addbackground"],
        // static: true,
        // sticky: true,
        updateOnEmptySelection: true,

        // align: "left",
      },
      extensions: {
        breathbutton: EditorButton({
          name: "breathbutton",
          icon: "fa-flask",
          label: "Insert Breath",
          buttonClass: "breath-button",
          onClick: showBreathPopup,
        }),
        uploadbutton: EditorButton({
          name: "uploadbutton",
          icon: "fa-upload",
          label: "Upload Audio",
          buttonClass: "upload-button",
          onClick: () => document.querySelector("#uploadAudio").click(),
        }),
        addbackground: EditorButton({
          name: "addbackground",
          icon: "fa-plus",
          label: "Add Background",
          buttonClass: "add-background-button",
          onClick: showBackgroundPopup,
        }),
      },
      buttonLabels: "fontawesome",
      placeholder: false,
      keyboardCommands: false,
      // disableEditing: true,
    });
    const editableElement = document.getElementById(`editor${props.order}`);
    editableElement.addEventListener("click", onEditableClick);
    newEditor.subscribe("editableKeydown", handleKeyDown);
    newEditor.subscribe("editableKeypress", handleKeyDown);
    setEditor(newEditor);
  };

  // Load Buffer in Waveform initially
  useEffect(() => {
    if (originalAudioBuffer[origDataId]) {
      editor.subscribe("editableInput", handleContentChange);
      editor.subscribe("editableKeyup", handleSelection);
      editor.subscribe("editableClick", handleSelection);
      const newArrayBuffer = toWav(originalAudioBuffer[origDataId]);
      const newBlob = new Blob([newArrayBuffer], { type: "audio/mp3" });
      waveform.loadBlob(newBlob);
      waveform.on("audioprocess", handleWaveFormSeek);
      waveform.on("seek", handleWaveFormSeek);
    }
  }, [originalAudioBuffer]);

  // Play or Pause Audio
  const handlePlay = () => {
    const newPlaying = !playing;
    playingRef.current = newPlaying;
    setPlaying(newPlaying);
    waveform.playPause();
    if (!playingRef.current) {
      for (let i = 0; i < backgroundsRef.current.length; i++) {
        const bg = backgroundsRef.current[i];
        if (!bg.sound.paused) {
          bg.sound.pause();
        }
      }
    }
  };

  // Highlight text in editor when seeking through Waveform
  const handleWaveFormSeek = (e) => {
    const currentTime = waveform.getCurrentTime();
    if (playingRef.current) {
      for (let i = 0; i < backgroundsRef.current.length; i++) {
        const bg = backgroundsRef.current[i];
        if (currentTime >= bg.st && currentTime <= bg.et) {
          if (playingRef.current && bg.sound.paused) {
            bg.sound.volume = 0.05;

            bg.sound.play();
          }
        } else {
          bg.sound.currentTime = 0;

          if (!bg.sound.paused) {
            bg.sound.pause();
          }
        }
      }
    }

    document
      .querySelectorAll(
        `#editor${props.order} .audio-block:not([d-type="space"])`
      )
      .forEach((elm) => {
        const elmDetails = getElementDetails(elm);
        if (currentTime < elmDetails.et && currentTime >= elmDetails.st) {
          if (!elm.classList.contains("highlighted")) {
            elm.classList.add("highlighted");
          }
        } else {
          if (elm.classList.contains("highlighted")) {
            elm.classList.remove("highlighted");
          }
        }
      });
  };

  // Handle Deletion of words in editor
  const handleContentChange = (e) => {
    const audioBlocks = document.querySelectorAll(
      `#editor${props.order} .audio-block:not([d-type="space"])`
    );
    if (audioBlocks.length !== modifiedTranscribeData.items.length) {
      createAndSetNewBuffer();
    }
  };

  const handleSelection = (e) => {
    const selection = getSelection();
    if (selection) {
      waveform.clearRegions();
      if (selection.startElement != selection.endElement) {
        waveform.addRegion({
          id: "sample1",
          start: getElementDetails(selection.startElement).st, // time in seconds
          end: getElementDetails(selection.endElement).et, // time in seconds
          color: "hsla(100, 100%, 30%, 0.1)",
        });
      }

      const totalTime = waveform.getDuration();
      const startTime = getElementDetails(selection.startElement).st;
      const progress = startTime / totalTime;
      if (progress > 0) waveform.seekTo(progress);
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [37, 38, 39, 40, 8, 46];
    if (!allowedKeys.includes(e.keyCode)) {
      console.log("Not allowed Keystoke");
      e.preventDefault();
      return false;
    }
  };

  const addBreath = (seconds) => {
    hideBreathPopup();
    var selObj = window.getSelection();
    var range = selObj.getRangeAt(0);
    const startElement = range.startContainer.parentElement;
    const breathSpan = createBreathSpan(seconds, false);
    document
      .querySelector(`#editor${props.order}`)
      .insertBefore(breathSpan, startElement);
    createAndSetNewBuffer();
  };

  const showBreathPopup = () => {
    const breathPopup = document.querySelector(`#breathPopup${props.order}`);
    const toolbar = document.getElementById(
      `medium-editor-toolbar-${props.order + 1}`
    );

    setBreathPopupVisibility("block");
    setBackgroundPopupVisibility("none");
    breathPopup.style.top = `${
      Number(toolbar.style.top.replace("px", "")) + 70
    }px`;
    breathPopup.style.left = toolbar.style.left;
  };

  const hideBreathPopup = () => {
    setBreathPopupVisibility("none");
  };

  const showBackgroundPopup = () => {
    const backgroundPopup = document.querySelector(
      `#backgroundPopup${props.order}`
    );

    const toolbar = document.getElementById(
      `medium-editor-toolbar-${props.order + 1}`
    );

    console.log(toolbar);
    console.log(toolbar.style.top);
    setBackgroundPopupVisibility("block");
    setBreathPopupVisibility("none");
    backgroundPopup.style.top = `${
      Number(toolbar.style.top.replace("px", "")) + 70
    }px`;
    backgroundPopup.style.left = toolbar.style.left;
  };

  const hideBackgroundPopup = () => {
    setBackgroundPopupVisibility("none");
  };

  const createAndSetNewBuffer = async () => {
    setLoading(true);
    const modifiedTranscribeData = updateTranscribeData(originalTranscribeData);
    setModifiedTranscribeData(modifiedTranscribeData);
    updateTimesOnEditor(modifiedTranscribeData);
    console.log(
      "Modified Transcribe Data Items: ",
      modifiedTranscribeData.items.length
    );
    const newBuffer = await createNewBuffer(
      modifiedTranscribeData,
      originalAudioBuffer,
      origDataId
    );
    const newArrayBuffer = toWav(newBuffer);
    const newBlob = new Blob([newArrayBuffer], { type: "audio/mp3" });
    waveform.loadBlob(newBlob);
    setLoading(false);
  };

  const handleAudioUpload = (e) => {
    var selObj = window.getSelection();
    var range = selObj.getRangeAt(0);
    const startElement = range.startContainer.parentElement;
    if (e.target.files[0]) {
      setLoading(true);
      var formData = new FormData();
      formData.append("file", e.target.files[0], e.target.files[0].name);
      formData.append("json", true);

      axios
        .post(`${process.env.REACT_APP_BACKEND_URI}/transcribe`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          const responseData = resp.data.data;
          console.log(responseData);
          const id = uuid();
          addNewSpans(responseData.results, startElement, id);
          downloadAudioAndSaveToBuffer(responseData.audioSource, id);
          setOriginalTranscribeData({
            ...originalTranscribeData,
            [id]: { items: responseData.results, config: {} },
          });

          setLoading(false);
        });
      // .catch((e) => {
      //   message.error(e.message);
      //   setLoading(false);
      // });
    }
  };

  useEffect(() => {
    if (
      Object.keys(originalTranscribeData).length > 1 &&
      Object.keys(originalAudioBuffer).length > 1
    ) {
      createAndSetNewBuffer();
    }
  }, [originalTranscribeData, originalAudioBuffer]);

  const addNewSpans = (items, element, src) => {
    var documentFragment = document.createDocumentFragment();

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const nextItem = items[i + 1];
      let spaceOrNot = undefined;
      if (nextItem) {
        if (nextItem.type === "pronunciation") {
          spaceOrNot = document.createElement("span");
          spaceOrNot.classList.add("audio-block");
          spaceOrNot.setAttribute("d-type", "space");
          spaceOrNot.textContent = " ";
        }
      }

      const newSpan = document.createElement("span");
      newSpan.classList.add("audio-block");
      newSpan.setAttribute("d-src", src);
      newSpan.setAttribute("d-id", item.id);
      newSpan.setAttribute("d-type", item.type);
      newSpan.textContent = item.content;
      if (item.type === "pronunciation") {
        newSpan.classList.add("audible");
        newSpan.setAttribute("d-st", item.st);
        newSpan.setAttribute("d-et", item.et);
        newSpan.setAttribute("d-len", item.len);
      }

      documentFragment.appendChild(newSpan);
      if (spaceOrNot) documentFragment.appendChild(spaceOrNot);
    }

    document
      .querySelector(`#editor${props.order}`)
      .insertBefore(documentFragment, element);
  };

  const addBackgroundAudio = async (background, type) => {
    hideBackgroundPopup();

    addBreath(1);
    const selection = getSelection();
    if (!selection) {
      alert("Please select something");
      return;
    }
    if (selection.startElement === selection.endElement) {
      alert("Please select something");
      return;
    }
    waveform.clearRegions();
    setLoading(true);

    const startElementDetails = getElementDetails(selection.startElement);
    const endElementDetails = getElementDetails(selection.endElement);
    const backgroundLength = endElementDetails.st - startElementDetails.et + 22;
    const backgroundUrl = await getBackground(background, backgroundLength);
    const backgroundBuffer = await downloadAudioAndConvertToBuffer(
      backgroundUrl
    );
    const loopedBackgroundBase64 = await createLoopedAudio(
      backgroundBuffer,
      backgroundLength,
      "base64"
    );

    const sound = new Audio();
    sound.src = "data:audio/wav;base64," + loopedBackgroundBase64;
    sound.load();

    if (type === "delayed") {
      sound.onplay = function (e) {
        console.log("is registerd");
        // sound.fade(0, 0.26, 1000);
        $(sound).animate({ volume: 0.2 }, 3000);

        const interval = setInterval(() => {
          const currentPosition = Math.ceil(sound.currentTime * 1000);
          if (currentPosition > 6000) {
            clearInterval(interval);
            $(sound).animate({ volume: 0.05 }, 3000);

            // sound.fade(0.26, 0.1, 3000);
            setTimeout(() => {
              // sound.volume(0.1);
              const interval2 = setInterval(() => {
                const currentPosition = Math.ceil(sound.currentTime * 1000);
                if (currentPosition > backgroundLength * 1000 - 6000) {
                  console.log("Fading Out again");
                  clearInterval(interval2);
                  $(sound).animate({ volume: 0 }, 5500);

                  setTimeout(() => {
                    sound.currentTime = 0;
                  }, 5000);
                }
              }, 10);
            }, 3000);
          }
        }, 10);
      };
    } else {
      sound.volume = 0.1;
      // sound.volume(0.1);
    }

    sound.onloadedmetadata = () => {
      const newBackgrounds = [
        ...backgrounds,
        {
          id: uuid(),
          name: background,
          url: backgroundUrl,
          buffer: backgroundBuffer,
          st: startElementDetails.st,
          et: endElementDetails.et + (type === "delayed" ? 22 : 1),
          elementId: startElementDetails.id,
          elementSrc: startElementDetails.src,
          sound,
          type,
        },
      ];
      backgroundsRef.current = newBackgrounds;
      setBackgrounds(newBackgrounds);
      addDelaysForBackground(
        selection.startElement,
        selection.endElement,
        type
      );
      createAndSetNewBuffer();
      document.getSelection().removeAllRanges();
      setLoading(false);
    };
  };

  const addDelaysForBackground = (startElement, endElement, type, src) => {
    const breathSpan1 = createBreathSpan(type === "delayed" ? 9 : 0.5, true);
    const breathSpan2 = createBreathSpan(type === "delayed" ? 13 : 0.5, true);
    breathSpan1.setAttribute("d-src", src);
    breathSpan2.setAttribute("d-src", src);
    document
      .querySelector(`#editor${props.order}`)
      .insertBefore(breathSpan1, startElement);
    document
      .querySelector(`#editor${props.order}`)
      .insertBefore(breathSpan2, endElement.nextSibling);
  };

  useEffect(() => {
    console.log(backgrounds);
  }, [backgrounds]);

  console.log(`editor${props.order}${backgroundPopupVisibility}`);

  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <div
              className="spinner-parent"
              style={{ display: loading ? "flex" : "none" }}
            >
              <div className="text-center">
                <Spin size="large" />
                <h6 className="mt-3">Processing...</h6>
              </div>
            </div>
            <input
              type="file"
              id="uploadAudio"
              className="d-none"
              onChange={handleAudioUpload}
            />
            <div>
              <div id={`waveform${props.order}`} />
              <br />

              <div id={`editor${props.order}`}>
                {originalTranscribeData[origDataId].items.map((item, i) => {
                  const nextItem =
                    originalTranscribeData[origDataId].items[i + 1];
                  let spaceOrNot = "";
                  if (nextItem) {
                    spaceOrNot =
                      nextItem.type === "punctuation" ? (
                        ""
                      ) : (
                        <span className="audio-block" d-type="space">
                          {" "}
                        </span>
                      );
                  }
                  return (
                    <>
                      <span
                        // contentEditable='false'
                        className={`audio-block ${
                          item.type === "pronunciation" ? "audible" : ""
                        }`}
                        d-st={item.st}
                        d-et={item.et}
                        d-src={origDataId}
                        d-id={item.id}
                        d-type={item.type}
                        d-len={item.len}
                      >
                        {item.content}
                      </span>
                      {spaceOrNot}
                    </>
                  );
                })}
              </div>
              <div
                className="addItems d-none"
                ref={addElementRef}
                onClick={onPlusIconClick}
              >
                +
              </div>
              <div className="dropdown_cls d-none" ref={dropdownRef}>
                <div
                  className="dropdown-options"
                  onClick={onDropdownElementClick}
                >
                  <p className="heading">Spext Doc</p>
                  <p>Add a file from your drive</p>
                </div>
                <hr />
                <div
                  className="dropdown-options"
                  onClick={onDropdownElementClick}
                >
                  <p className="heading">Sound</p>
                  <p>Add music or sound effect</p>
                </div>
              </div>
              <button
                ref={props.playButton}
                className="btn btn-success mt-5"
                onClick={handlePlay}
                style={{ opacity: 0, position: "absolute", top: 0 }}
              >
                {!playing ? "Play" : "Pause"}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        id={`backgroundPopup${props.order}`}
        className="customPopup"
        style={{ display: backgroundPopupVisibility }}
      >
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBackgroundAudio("Funked Up", "delayed");
          }}
        >
          Funked Up (Delayed)
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBackgroundAudio("Funked Up", "immediate");
          }}
        >
          Funked Up (Immediate)
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBackgroundAudio("OMG!", "delayed");
          }}
        >
          OMG! (Delayed)
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBackgroundAudio("OMG!", "immediate");
          }}
        >
          OMG! (Immediate)
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBackgroundAudio("Trends & Styles", "delayed");
          }}
        >
          Trends & Styles (Delayed)
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBackgroundAudio("Trends & Styles", "immediate");
          }}
        >
          Trends & Styles (Immediate)
        </a>
      </div>
      <div
        id={`breathPopup${props.order}`}
        className="customPopup"
        style={{ display: breathPopupVisibility }}
      >
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBreath(0.5);
          }}
        >
          0.5 Second
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBreath(1);
          }}
        >
          1 Second
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBreath(2);
          }}
        >
          2 Second
        </a>
        <a
          className="popupButton"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            addBreath(3);
          }}
        >
          3 Second
        </a>
      </div>
    </>
  );
}

export default TranscribeEditor;

import React from "react";

function Backgrounds({ backgrounds }) {
  return (
    <div className="Backgrounds">
      <h5>Backgronds</h5>
      {backgrounds.map((b) => (
        <div className="Backgrounds-Tile">
          <p className="mb-0">{b.name}</p>
          <p className="mb-0">
            From {b.st} To {b.et}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Backgrounds;

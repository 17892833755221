import axios from 'axios';
import audioBufferUtils from 'audio-buffer-utils';
const audioCtx = new AudioContext();
var toWav = require('audiobuffer-to-wav');

const downloadAudioAndConvertToBuffer = (url, arrayBuffer = false) =>
  new Promise(async (resolve, reject) => {
    try {
      axios({ method: 'GET', url, responseType: 'arraybuffer' }).then((resp) => {
        if (arrayBuffer) return resp.data;
        audioCtx.decodeAudioData(resp.data, (audioBuffer) => {
          return resolve(audioBuffer);
        });
      });
    } catch (error) {
      console.log(`downloadAudioAndConvertToBuffer Error: ${error}`);
      return reject(error);
    }
  });

// returnType can be "audiobuffer", "base64" or "arraybuffer"
const createLoopedAudio = (audioBuffer, length, returnType) =>
  new Promise(async (resolve, reject) => {
    try {
      if (audioBuffer.duration > length) {
        if (returnType === 'audiobuffer') return resolve(audioBuffer);
        const arrayBuffer = toWav(audioBuffer);
        if (returnType === 'arraybuffer') return resolve(arrayBuffer);
        const base64 = arrayBufferToBase64(arrayBuffer);
        return resolve(base64);
      } else {
        const repeats = Math.ceil(length / audioBuffer.duration);
        let finalBuffer = audioBuffer;
        for (let i = 0; i < repeats - 1; i++) {
          finalBuffer = audioBufferUtils.concat(finalBuffer, audioBuffer);
        }
        if (returnType === 'audiobuffer') return resolve(finalBuffer);
        const arrayBuffer = toWav(finalBuffer);
        if (returnType === 'arraybuffer') return resolve(arrayBuffer);
        const base64 = arrayBufferToBase64(arrayBuffer);
        return resolve(base64);
      }
    } catch (error) {
      console.log('createLoopedAudio Error: ', error);
      reject(error);
    }
  });

const arrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export { downloadAudioAndConvertToBuffer, createLoopedAudio, arrayBufferToBase64 };

import React from "react";
import style from "../styles/addNew.module.css";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { message, Spin, Modal } from "antd";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setProjectName } from "../redux/actions";

export default function AddItemPopup() {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [projectName, setName] = useState("");
  const dispatch = useDispatch();

  const cards = [
    { icon: "podcast.svg", title: "Podcast (MP3)" },
    { icon: "instagram-stories.svg", title: "Story" },
    { icon: "youtube.svg", title: "video (MP4)" },
    { icon: "audio-gram.svg", title: "audiogram" },
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // setIsModalVisible(false);
  };

  const onSubmit = () => {
    setIsModalVisible(false);
    dispatch(setProjectName(projectName));
    setName("");
  };
  return (
    <>
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={"addItemPopup buildProjectPopup"}
      >
        <div className={style.BuildProject}>
          <div className={style.tracksSection} style={{ height: "500px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <h2>Enter Project Name</h2>
              {/* <img
                src="/images/close.svg"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsModalVisible(false);
                }}
                alt=""
              /> */}
            </div>
            <h6>
              <b>
                {" "}
                <img src="/images/build.svg" alt="" />
                Please enter a name for your project
              </b>
            </h6>
            <div>
              <input
                type="text"
                placeholder="Enter Name"
                className="mb-4 input"
                value={projectName}
                onChange={(e) => setName(e.target.value)}
              />
              <br />
              <button onClick={onSubmit} className="btn btn-success">
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

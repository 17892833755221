import React, { useEffect, useState } from "react";
import style from "../styles/layout.module.css";
import { useHistory, useLocation } from "react-router-dom";
import AddItemPopup from "./AddItemPopup";
import BuildProjectPopup from "./BuildProjectPopup";

export default function Sidebar() {
  const history = useHistory();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    if (history.location.pathname !== "/") setShowSidebar(true);
    else setShowSidebar(false);
  }, [location]);
  return (
    <div className={style.sidebar}>
      {showSidebar ? (
        <>
          {" "}
          <AddItemPopup />
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/placeholder");
            }}
          >
            <img src="/images/Place_Holder.svg" alt="" />
            Placeholder
          </a>
          <BuildProjectPopup />
        </>
      ) : (
        ""
      )}
    </div>
  );
}

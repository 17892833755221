import React, { useState, useEffect } from "react";
import style from "../../styles/sideMenu.module.css";
import voices from "../../data/voices.json";
import ImageInitial from "../ImageInitial";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedItem } from "../../redux/actions";
export default function PlaceholderSidebar() {
  const [selectedVoice, setSelectedVoice] = useState({});
  const dispatch = useDispatch();
  const { activeItem } = useSelector((state) => state.placeholder);

  useEffect(() => {
    if (activeItem.type == "tts" && activeItem.voice) {
      setSelectedVoice(activeItem.voice);
    }
  }, [activeItem]);

  return (
    <div>
      <h2>TEXT TO SPEECH</h2>
      <div className={style.invite}>
        <input type="text" placeholder="Find People to Invite" />
        <button>INVITE</button>
      </div>
      <h4
        className="mb-4 mt-5"
        style={{
          fontSize: 17,
          fontWeight: "bold",
        }}
      >
        Voices
      </h4>
      <div
        className={style.userInvitation}
        style={{ overflow: "auto", height: "64vh", paddingBottom: "3em" }}
      >
        {voices.map((item) => (
          <div
            className={`${style.userItem} d-flex mt-3 align-items-center`}
            onClick={() => {
              setSelectedVoice(item);
              dispatch(
                updateSelectedItem({
                  ...activeItem,
                  voice: item,
                })
              );
            }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ flex: 1 }}
            >
              <div className="d-flex align-items-center w-100">
                <div style={{ marginRight: "1em" }}>
                  <ImageInitial fullname={item.fullname} image={item.avatar} />
                </div>
                <div
                  className="d-flex justify-content-between "
                  style={{ flex: 1, paddingRight: "2em" }}
                >
                  <div>
                    <h6
                      style={{
                        color:
                          selectedVoice.fullname == item.fullname
                            ? "#38ef7d"
                            : "black",
                      }}
                    >
                      {item.fullname}
                    </h6>
                    <p> {item.premium ? "Premium" : ""} Narration</p>
                  </div>
                  <img src="/images/blackPlayIcon.svg" />{" "}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

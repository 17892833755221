import React from "react";
import style from "../../styles/sideMenu.module.css";
import { Tag } from "antd";
import CreateSidebar from "./CreateSidebar";
import PlaceholderSidebar from "./PlaceholderSidebar";
import { useSelector } from "react-redux";
import VoiceSidebar from "./VoiceSidebar";
import MusicSidebar from "./MusicSidebar";
export default function RightSidebar() {
  const { rightSidebar } = useSelector((state) => state.placeholder);

  return rightSidebar ? (
    <div className={style.sideMenu}>
      {rightSidebar == "create" ? (
        <CreateSidebar />
      ) : rightSidebar == "voice" ? (
        <VoiceSidebar />
      ) : rightSidebar == "music" ? (
        <MusicSidebar />
      ) : (
        ""
      )}

      {/* <PlaceholderSidebar /> */}
    </div>
  ) : (
    ""
  );
}

import React from "react";
import style from "../../styles/sideMenu.module.css";
import { Tag } from "antd";

export default function CreateSidebar() {
  return (
    <div>
      <div className={style.invite}>
        <input
          type="text"
          placeholder="Search For Categories"
          className="me-0"
        />
      </div>
      <div className={`${style.actionCards} d-flex`}>
        <div className={style.active}>
          <img src="/images/categories2.svg" alt="" />
          <p>Search for Categories</p>
        </div>
        <div>
          <img src="/images/collections.svg" alt="" />
          <p>Search Collections</p>
        </div>
      </div>
      <div className={style.userInvitation}>
        <button className={style.primaryBtn}>Categories</button>
        <div className="mt-4">
          <Tag
            color="transparent"
            style={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            Sports
          </Tag>
          <Tag
            color="transparent"
            style={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            Coworking
          </Tag>
          <Tag
            color="transparent"
            style={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            Business
          </Tag>
          <Tag
            color="transparent"
            style={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            Finance
          </Tag>
          <Tag
            color="transparent"
            style={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            Hiking
          </Tag>
          <Tag
            color="transparent"
            style={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            Driverless Vehicles
          </Tag>
          <Tag
            color="transparent"
            style={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "0.5em",
            }}
          >
            Architecture
          </Tag>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import style from "../styles/recordBar.module.css";
import { Avatar, Divider, Tooltip } from "antd";
import {
  UserOutlined,
  AntDesignOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { submitTranscribeForm, addItem } from "../redux/actions";
import { message, Spin, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateSelectedItem,
  setRecording as setStoreRecording,
} from "../redux/actions";

function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export default function RecordBar() {
  const [recording, setRecording] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [timer, setTimer] = React.useState(false);
  const [recordedData, setRecordedData] = React.useState(null);
  const [recorder, setRecorder] = React.useState(null);
  const [stream, setStream] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);
  const [noiseCancellation, setNoiseCancellation] = React.useState(false);
  const [audioEnhancement, setAudioEnhancement] = React.useState(false);
  const [recordedFile, setRecordedFile] = React.useState(false);
  const [transcribeLoading, setTranscribeLoading] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { activeItem } = useSelector((state) => state.placeholder);
  const startRecorder = () => {
    setRecording(true);
    dispatch(setStoreRecording(true));
    // startRecording();

    var AudioContext = window.AudioContext || window.webkitAudioContext;
    var audioContext = new AudioContext();

    var constraints = {
      audio: { echoCancellation: false },
      video: false,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (str) {
        var rec;
        var input;
        var gumStream;
        console.log("inside here");
        gumStream = str;
        input = audioContext.createMediaStreamSource(str);

        setStream(str);
        console.log("inside here");

        rec = new window.WebAudioRecorder(input, {
          workerDir: "/js/", // must end with slash
          encoding: "wav",
          numChannels: 1, //2 is the default, mp3 encoding supports only 2
          onEncoderLoading: function (rec, encoding) {
            // show "loading encoder..." display
            console.log("Loading " + encoding + " encoder...");
          },
          onEncoderLoaded: function (rec, encoding) {
            // hide "loading encoder..." display
            console.log(encoding + " encoder loaded");
            const tim = setInterval(() => {
              setTime((time) => time + 1);
            }, 1000);
            setTimer(tim);
          },
        });
        console.log("setting recorder", rec);
        setRecorder(rec);

        rec.onComplete = function (rec, blob) {
          const fileName = `${uuidv4()}recording.wav`;

          const reader = new FileReader();
          reader.onload = async function (e) {
            const srcUrl = e.target.result;
            setRecording(false);
            dispatch(setStoreRecording(false));

            setRecordedData(srcUrl);
            setProcessing(true);
            submitForm(blob, fileName);

            setRecordedFile({
              content: blob,
              name: fileName,
            });

            // startRecordPlay(response);
          };
          reader.readAsDataURL(blob);
        };

        rec.setOptions({
          timeLimit: 120,
          encodeAfterRecord: true,
          bufferSize: 16384,
        });

        //start the recording process
        rec.startRecording();
      })
      .catch(function (err) {});
  };
  const stopRecorder = async () => {
    setRecording(false);
    dispatch(setStoreRecording(false));
    clearInterval(timer);
    setTime(0);
    stream.getAudioTracks()[0].stop();

    recorder.finishRecording();
  };
  async function submitForm(file, filename) {
    setTranscribeLoading(true);
    const result = await submitTranscribeForm(file, filename);

    setTranscribeLoading(false);

    if (result) {
      dispatch(updateSelectedItem({ ...activeItem, ...result }));
    }
  }
  return (
    <>
      <div className={style.footer}>
        <div className="d-flex">
          <div>
            <img src="/images/blackPlayIcon.svg" alt="" />
            <p>Record Interview</p>
          </div>
          <div>
            <span style={{ fontSize: "1.4em" }}>
              <b>{fancyTimeFormat(time)}</b>{" "}
            </span>
            <p>Duration</p>
          </div>
        </div>
        <div
          className="text-center"
          onClick={() => {
            if (recording) {
              stopRecorder();
            } else {
              startRecorder();
            }
          }}
        >
          {transcribeLoading ? (
            <>
              <Spin />
            </>
          ) : (
            <>
              {recording ? (
                <img src="/images/record_button.svg" alt="" />
              ) : (
                <img src="/images/recordIconBlack.svg" alt="" />
              )}
              <p>Record Interview</p>
            </>
          )}
        </div>
        <div className="d-flex">
          <div>
            <img src="/images/noiseCancellationIcon.svg" alt="" />
            <p>Noise Cancellation</p>
          </div>
          <div>
            <img src="/images/audioEnhancementIcon.svg" alt="" />
            <p>Audio Enhancement</p>
          </div>
        </div>
      </div>
    </>
  );
}

import MediumEditor from 'medium-editor';

const BreathButton = ({ name, label, icon, buttonClass, onClick }) => {
  const Button = MediumEditor.extensions.button.extend({
    name: name,
    contentFA: `<i class="fa ${icon}"></i>`,
    aria: label,
    classList: ['editor-button', buttonClass],

    init: function () {
      MediumEditor.extensions.button.prototype.init.call(this);

      // this.classApplier = rangy.createClassApplier('highlight', {
      //     elementTagName: 'mark',
      //     normalize: true
      // });
    },

    handleClick: onClick,
  });

  return new Button();
};

export default BreathButton;

import React from "react";
import style from "../styles/placeHolder.module.css";
import { BsPlayCircle } from "react-icons/bs";
import TranscribeEditor from "./TranscribeEditor";
import { useSelector } from "react-redux";
export default function RecordNowEditor({
  audioData,
  playButton,
  playing,
  setPlaying,
  order,
}) {
  const { activeItem, recording } = useSelector((state) => state.placeholder);

  const recordStatus = (
    <div className={style.music}>
      <div>
        <div className="d-flex align-items-center">
          <p className="mb-0" style={{ color: "#aeaeae" }}>
            Recording Now
          </p>
        </div>
      </div>
    </div>
  );

  const startRecordStatus = (
    <div className={style.music}>
      <div>
        <div className="d-flex align-items-center">
          <p className="mb-0" style={{ color: "black" }}>
            Start Recording
          </p>
        </div>
      </div>
    </div>
  );
  return activeItem.id == audioData.id && recording ? (
    recordStatus
  ) : audioData.results ? (
    <TranscribeEditor
      audioData={audioData}
      playButton={playButton}
      playing={playing}
      setPlaying={setPlaying}
      order={order}
    />
  ) : (
    startRecordStatus
  );
}

import React from "react";
import style from "../styles/layout.module.css";

export default function Header() {
  return (
    <div className={style.Header}>
      <a href="">
        <img src="/images/logo.svg" alt="" />
      </a>
      <div className="d-flex align-items-center">
        <a href="#" className="me-3">
          <img src="/images/settingIcon.svg" alt="" />
        </a>
        <a href="#" className="">
          <div className="d-flex align-items-start">
            <img src="/images/Avatar.png" alt="" className="me-2" />
            <div>
              <p>Charlize G.</p>
              <p>Silver member</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

import {
  ADD_ITEM,
  SET_PROJECT_NAME,
  SHOW_SIDEBAR,
  SET_ACTIVE_ITEM,
  SHOW_RECORD_BAR,
  SET_SELECTED_VOICE,
  UPDATE_SELECTED_ITEM,
  SET_RECORDING,
} from "../constants";

var defaultState = {
  items: [],
  projectName: null,
  rightSidebar: "create",
  activeItem: null,
  showRecordBar: false,
  selectedSidebar: null,
  recording: false,
};
var authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_ITEM: {
      let newState = Object.assign({}, state);
      newState.items = [...newState.items, action.payload];

      return newState;
    }
    case SET_ACTIVE_ITEM: {
      let newState = Object.assign({}, state);
      newState.activeItem = action.payload;
      if (newState.activeItem.type == "tts") newState.rightSidebar = "voice";
      else if (newState.activeItem.type == "music")
        newState.rightSidebar = "music";
      else if (newState.activeItem.type == "record") {
        newState.showRecordBar = true;
        newState.rightSidebar = false;
      } else {
        newState.showRecordBar = false;
        newState.rightSidebar = false;
      }
      return newState;
    }
    case UPDATE_SELECTED_ITEM: {
      let newState = Object.assign({}, state);
      newState.activeItem = action.payload;
      newState.items = newState.items.map((item) => {
        return item.id == action.payload.id ? action.payload : item;
      });
      return newState;
    }

    case SET_RECORDING: {
      let newState = Object.assign({}, state);
      newState.recording = action.payload;
      return newState;
    }

    case SHOW_RECORD_BAR: {
      let newState = Object.assign({}, state);
      newState.showRecordBar = action.payload;
      return newState;
    }

    case SET_PROJECT_NAME: {
      let newState = Object.assign({}, state);
      newState.projectName = action.payload;
      return newState;
    }
    case SET_SELECTED_VOICE: {
      let newState = Object.assign({}, state);
      newState.selectedSidebar = action.payload;
      return newState;
    }

    case SHOW_SIDEBAR: {
      let newState = Object.assign({}, state);
      newState.rightSidebar = action.payload;
      return newState;
    }

    default:
      return state;
  }
};
export default authReducer;

import _ from 'underscore';
const backgrounds = [
  {
    name: 'Funked Up',
    url: 'https://storage.googleapis.com/audio_one_background_music/190_full_funk-d-up_0175.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.4,
    fullLength: 155,
    loops: [
      'https://storage.googleapis.com/audio_one_background_music/190_loop1_funk-d-up_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop2_funk-d-up_0018.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop3_funk-d-up_0018.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop4_funk-d-up_0018.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop5_funk-d-up_0018.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop6_funk-d-up_0018.wav',
    ],
  },
  {
    name: 'OMG!',
    url: 'https://storage.googleapis.com/audio_one_background_music/190_instrumental1_omg_0219.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.3,
    fullLength: 219,
    loops: [
      'https://storage.googleapis.com/audio_one_background_music/190_loop1_omg_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop2_omg_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop3_omg_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop4_omg_0018.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop5_omg_0018.wav',
      'https://storage.googleapis.com/audio_one_background_music/190_loop6_omg_0018.wav',
    ],
  },
  {
    name: 'Trends & Styles',
    url: 'https://storage.googleapis.com/audio_one_background_music/216_full_trends-styles_0136.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.3,
    fullLength: 136,
    loops: [
      'https://storage.googleapis.com/audio_one_background_music/216_loop1_trends-styles_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/216_loop2_trends-styles_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/216_loop3_trends-styles_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/216_loop4_trends-styles_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/216_loop5_trends-styles_0009.wav',
      'https://storage.googleapis.com/audio_one_background_music/216_loop6_trends-styles_0009.wav',
    ],
  },
  {
    name: 'Shut Up and Take My Money',
    url: 'https://storage.googleapis.com/audio_one_background_music/232_full_shutup-and-take-my-money_0151.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.4,
    fullLength: 151,
    loops: ['https://storage.googleapis.com/audio_one_background_music/232_full_shutup-and-take-my-money_0151.mp3'],
  },
  {
    name: 'Glitter Bomb',
    url: 'https://storage.googleapis.com/audio_one_background_music/258_full_glitter-bomb_0142.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.4,
    fullLength: 142,
    loops: [
      'https://storage.googleapis.com/audio_one_background_music/258_loop1_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop2_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop3_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop4_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop5_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop6_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop7_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop8_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop9_glitter-bomb_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/258_loop10_glitter-bomb_0008.wav',
    ],
  },
  {
    name: 'Party Vibes',
    url: 'https://storage.googleapis.com/audio_one_background_music/364_full_party-vibes_0148.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.2,
    fullLength: 148,
    loops: [
      'https://storage.googleapis.com/audio_one_background_music/364_loop1_party-vibes_0008.wav',
      'https://storage.googleapis.com/audio_one_background_music/364_loop2_party-vibes_0016.wav',
      'https://storage.googleapis.com/audio_one_background_music/364_loop3_party-vibes_0016.wav',
      'https://storage.googleapis.com/audio_one_background_music/364_loop4_party-vibes_0016.wav',
      'https://storage.googleapis.com/audio_one_background_music/364_loop5_party-vibes_0016.wav',
      'https://storage.googleapis.com/audio_one_background_music/364_loop6_party-vibes_0016.wav',
      'https://storage.googleapis.com/audio_one_background_music/364_loop7_party-vibes_0016.wav',
    ],
  },
  {
    name: 'Just Rolling',
    url: 'https://storage.googleapis.com/audio_one_background_music/422_full_just-rolling_0138.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.4,
    fullLength: 138,
    loops: ['https://storage.googleapis.com/audio_one_background_music/422_full_just-rolling_0138.mp3'],
  },
  {
    name: 'Out Of Control',
    url: 'https://storage.googleapis.com/audio_one_background_music/530_full_out-of-control_0156.mp3',
    volumeLevel: 1,
    loweredVolumeLevel: 0.2,
    fullLength: 156,
    loops: [
      'https://storage.googleapis.com/audio_one_background_music/530_loop1_out-of-control_0010.wav',
      'https://storage.googleapis.com/audio_one_background_music/530_loop2_out-of-control_0010.wav',
      'https://storage.googleapis.com/audio_one_background_music/530_loop3_out-of-control_0010.wav',
      'https://storage.googleapis.com/audio_one_background_music/530_loop4_out-of-control_0010.wav',
      'https://storage.googleapis.com/audio_one_background_music/530_loop5_out-of-control_0010.wav',
      'https://storage.googleapis.com/audio_one_background_music/530_loop6_out-of-control_0010.wav',
    ],
  },
];

const getBackground = (background, backgroundLength) =>
  new Promise(async (resolve, reject) => {
    try {
      const foundBackground = backgrounds.find((b) => b.name.toLowerCase() === background.toLowerCase());
      if (backgroundLength <= foundBackground.fullLength) return resolve(foundBackground.url);
      const backgroundLoopUrl = _.sample(foundBackground.loops);

      return resolve(backgroundLoopUrl);
    } catch (error) {
      console.log(`getBackground Error: ${error}`);
      return reject(error);
    }
  });

export { getBackground };
